import React from "react"
import {graphql, StaticQuery, useStaticQuery} from "gatsby"
import DonationCard from "./DonationCard" //highlight-line
import styles from "../../css/donation.module.css"

const getPrices = graphql`
        query {
          prices: allStripePrice(
            filter: { active: { eq: true } }
            sort: { fields: [unit_amount] }
          ) {
            edges {
              node {
                id
                active
                currency
                unit_amount
                product {
                  id
                  name
                }
              }
            }
          }
        }
`

const DonationList = () => {

    const {prices} = useStaticQuery(getPrices)

    // highlight-start
    // Group prices by product
    const products = {}
    for (const {node: price} of prices.edges) {
        const product = price.product
        if (!products[product.id]) {
            products[product.id] = product
            products[product.id].prices = []
        }
        products[product.id].prices.push(price)
    }


    return (
        <div className={styles.center}>
            <h1>In giving we experience joy</h1>
            <input type="text"  id='campaign' placeholder='Campaign Name / Designation (Optional)' height='20px' width='200px'></input>
            <br/>
            {Object.keys(products).map((key) => (
                Object.values(products[key].prices).map((price) => (

                        <DonationCard price={price}/>
                    ))
            ))}
        </div>
    )
    // highlight-end

}

export default DonationList
