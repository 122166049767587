import React, {useState} from "react"
import getStripe from "../Donation/stripejs"
import styles from "../../css/donation.module.css"
import {
    FaHandsHelping,
    FaHeartbeat,
    FaGifts, FaTwitter, FaDonate, FaRegHeart, FaHandHoldingHeart
} from "react-icons/fa"

var axios = require("axios");

const buttonStyles = {
    display: "block",
    fontSize: "13px",
    textAlign: "center",
    color: "#000",
    padding: "12px",
    boxShadow: "2px 5px 10px rgba(0,0,0,.1)",
    backgroundColor: "rgb(255, 178, 56)",
    borderRadius: "6px",
    letterSpacing: "1.5px",
}

const buttonDisabledStyles = {
    opacity: "0.5",
    cursor: "not-allowed",
}

const formatPrice = (amount, currency) => {
    let price = (amount / 100).toFixed(2)
    let numberFormat = new Intl.NumberFormat(["en-US"], {
        style: "currency",
        currency: currency,
        currencyDisplay: "symbol",
    })
    return numberFormat.format(price)
}

const DonationCard = ({price}) => {
    const [loading, setLoading] = useState(false)

    const handleSubmit = async event => {
        event.preventDefault()
        setLoading(true)
        // console.log(' price ' + this);
        console.log(document.getElementById('campaign').value);
        // const price = new FormData(event.target).get(price.id)
       const price = event.target.getElementsByTagName('button')[0].id;
        const stripe = await getStripe()

        ////////

        const apiUrl = `https://xvobsixhna.execute-api.us-east-1.amazonaws.com/prod/`;

        var actName = 'actualVal' + price;
        let campaignText = document.getElementById('campaign').value;
        let actualPrice = document.getElementById(actName).value;

        const config = {
            headers: {
                'Content-Type': 'application/json',
                'X-Api-Key': 'Va9xNSTwkp6TubbOa7Vk09CjI1XqqdeT7Q1gQdQM'
            },
            mode: 'no-cors'
        };

        const payload = {
            "action": 'campaign',
            "campaignText": campaignText,
            "actualPrice": actualPrice
        };

        axios.post(apiUrl,JSON.stringify(payload), config)
            .then((response) => {
                console.log(response);
                this.setState({
                    loading: false,
                    repos: response.data });

            })
            .catch((err) => {
                console.log('error from API', err);
            });


        ///////

        const {error} = await stripe.redirectToCheckout({
            mode: "payment",
            lineItems: [{price, quantity: 1}],
            successUrl: `${window.location.origin}/`,
            cancelUrl: `${window.location.origin}/`,
        })

        if (error) {
            console.warn("Error:", error)
            setLoading(false)
        }
    }

    return (
        <div className={styles.cardStyles}>
            <form onSubmit={handleSubmit}>
                    <div>
                        <fieldset style={{border: "none"}}>
                            <label>
                                <label>{formatPrice(price.unit_amount, price.currency)}</label>
                                <input id={'actualVal' + price.id} type="text" hidden="hidden" value={price.unit_amount/100}/>
                                {/*<input type="checkbox" id={price.id}*/}
                                {/*       name={formatPrice(price.unit_amount, price.currency)} value={price.id}>*/}
                                {/*</input> */}
                                &nbsp;&nbsp;&nbsp;
                                {/*{formatPrice(price.unit_amount, price.currency)}*/}

                            </label>
                        </fieldset>
                        <button type="submit"
                                value={formatPrice(price.unit_amount, price.currency)}
                                id = {price.id}
                                disabled={loading}
                                style={
                                    loading
                                        ? {...buttonStyles, ...buttonDisabledStyles}
                                        : buttonStyles
                                }
                        >
                            <FaHandHoldingHeart/> &nbsp;
                            Donate
                        </button>
                    </div>

            </form>
        </div>
    )
}

export default DonationCard
