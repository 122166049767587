import React from "react"

import Layout from "../components/Layout"
import SEO from "../components/SEO"
import DonationList from "../components/Donation/DonationList";
import {graphql} from "gatsby";

import StyledHero from "../components/StyledHero"

import Banner from "../components/Banner";

export const query = graphql`
query {
    defaultBcg: file(relativePath: {eq: "replace-house-sunlight.jpg"}) {
        childImageSharp {
            fluid(quality: 90, maxWidth: 1160) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
}
`;

const Donation = ({ data }) => (
    <Layout>
        <SEO title="Donate for a cause" />
        <StyledHero img={data.defaultBcg.childImageSharp.fluid}>
            <Banner
                title="Donate"
                info="Lets save life together."
            >
            </Banner>
        </StyledHero>
        {/*<h1>Great options to donate</h1>*/}
        <DonationList /> {/* highlight-line */}
    </Layout>
)

export default Donation

